import { Component, OnInit, ElementRef  } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from '../auth.service';
import { GenericResponseTemplateModel, INotification } from '../generic-type-module';
import { AppHttpRequestHandlerService } from '../shared/app-http-request-handler.service';
import { SharingDataService } from '../services/sharing-data/sharing-data.service';

@Component({
  selector: 'app-notifiation-all',
  templateUrl: './notifiation-all.component.html',
  styleUrls: ['./notifiation-all.component.css']
})
export class NotifiationAllComponent implements OnInit {

  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  notifications: INotification[]=[];
  constructor(private appHttpRequestHandlerService: AppHttpRequestHandlerService, 
    public authService: AuthService,
    private router: Router,
    private sharingdataService: SharingDataService
    ) { }

  ngOnInit(): void {
    this.getAllUnreadNotifications(this.authService.getUserJwtDecodedInfo().UserId);
  }
  getAllUnreadNotifications(userId){
    this.appHttpRequestHandlerService.httpGet({ id: userId }, "NotificationManager", "GetAllNotifications")
    .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: GenericResponseTemplateModel<INotification[]>) => {
       this.notifications = data.responseDataModel;
       //console.log('notilist',this.notifications)
      });
  }
  goToHomePage(event: any) {
    event.preventDefault();
    const targetElement = event.target as HTMLElement;
    const notificationBox = targetElement.closest('.notification-box');
    if (notificationBox) {
      const dateNotiElement = notificationBox.querySelector('.date-noti') as HTMLElement;
  
      if (dateNotiElement) {
        const dateValue = dateNotiElement.textContent?.trim();
        const normalizedDateString = dateValue.replace(" at", "");

      // Try creating a Date object again
      const dateObject = new Date(normalizedDateString);
      const options: Intl.DateTimeFormatOptions = { month: 'long' }; // Correct usage with the appropriate type
      // Extract the month name
     const month = dateObject.toLocaleString('en-US', options);
     // Extract the year
      const year = dateObject.getFullYear();
      this.sharingdataService.setHrSharingData(true, month, year);
        //console.log("Found date: " + dateValue);
      } else {
       // console.log("Date-noti element not found within notification-box");
      }
    } else {
      //console.log("Notification box not found");
    }
    localStorage.setItem("name", "true");
    this.router.navigate(['/HomeAddress/Dashboard']);
  }
}
