import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: ['./legal.component.css']
})
export class LegalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    if(localStorage.getItem("myHome") === 'true')
      {
        localStorage.setItem("myHome", 'true');
      }
      else{
        localStorage.setItem("myHome", 'false');
      }
  }

}
