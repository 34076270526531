<section class="demo-text-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="privacy-text">
                    <h2>Privacy & Terms</h2>
                    <p>Upkeepable ("we", "us", "our") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share information about you when you use our website, Upkeepable.com (the "Site"), and our services (together
                        with the Site, the "Services").</p>

                    <p>By using our Services, you agree to the collection, use, and sharing of your information as described in this Privacy Policy. If you do not agree with our policies and practices, do not use our Services.</p>

                    <p>We may change our policies and practices from time to time, and we encourage you to review this Privacy Policy periodically. If we make any material changes, we will post the updated policy on this page and indicate the effective date
                        of the changes at the top of the page. Your continued use of our Services after the changes become effective indicates your acceptance of the changes.</p>
                </div>

                <div class="privacy-text">
                    <h6>1. Information We Collect and How We Collect It</h6>
                    <p>We collect information about you in several ways when you use our Services.</p>

                    <ul>
                        <li>Information You Provide to Us: We collect information you provide directly to us, such as when you create an account, contact us, or request information. This may include your name, email address, phone number, and any other information
                            you choose to provide.</li>

                        <li>Information We Collect Automatically: When you use our Services, we automatically collect certain information about your device and usage of our Services. This may include your IP address, device type, browser type, operating system,
                            referral source, length of visit, and page views. We may also collect information about your location, including location data from your device or IP address.</li>

                        <li>Cookies and Other Tracking Technologies: We use cookies and similar tracking technologies to collect information about your activity on our Services and hold certain information. Cookies are small text files that are placed on
                            your device when you visit a website. We use cookies to store your preferences and settings, to help personalize your experience on our Services, and to track and analyze your use of our Services. You can control the use of
                            cookies at the individual browser level, but if you choose to disable cookies, it may limit your use of certain features or functions on our Services.</li>
                    </ul>
                </div>

                <div class="privacy-text">
                    <h6>2. How We Use Your Information</h6>
                    <p>We use the information we collect about you for a variety of purposes, including:</p>
                    <ul>
                        <li>Providing, maintaining, and improving our Services;</li>
                        <li>Personalizing your experience on our Services;</li>
                        <li>Communicating with you, including responding to your requests and inquiries;</li>
                        <li>Analyzing and understanding how you use our Services;</li>
                        <li>Protecting the security and integrity of our Services; and</li>
                        <li>Complying with legal and regulatory obligations.</li>
                    </ul>
                </div>

                <div class="privacy-text">
                    <h6>3. Sharing Your Information</h6>
                    <p>We may share your information in the following circumstances:</p>

                    <ul>
                        <li>With third-party service providers who perform services on our behalf, such as website hosting, data analysis, payment processing, and marketing assistance;
                        </li>

                        <li>In response to a request for information if we believe disclosure is required or permitted by law, such as in response to a court order or subpoena;</li>

                        <li>With law enforcement or other government agencies, or with other third parties, if we believe your actions are inconsistent with our user agreements or policies, or to protect the rights, property, or safety of Upkeepable or others;
                            and
                        </li>

                        <li>With other users of our Services if you choose to share information or interact with them.We may also share aggregated or anonymized information that does not directly identify you.</li>
                    </ul>
                </div>

                <div class="privacy-text">
                    <h6>4. Your Choices and Access to Your Information (continued)</h6>
                    <ul>
                        <li>Access and update your information: You can access and update your account information at any time by logging into your account on our Services.</li>

                        <li>Opt out of marketing communications: You can opt out of receiving marketing communications from us by following the unsubscribe instructions in the communications you receive or by contacting us at [Insert contact email or form].
                            Please note that if you opt out of marketing communications, you may still receive non-marketing communications from us, such as those related to your account or our ongoing business relations.</li>

                        <li>Manage your cookies: You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of our Services may become
                            inaccessible or not function properly.</li>

                        <li>Request deletion of your information: You can request that we delete your account and the personal information associated with it by contacting us at [Insert contact email or form]. Please note that we may not be able to delete
                            all of your information, as some information may be retained for legal or business purposes.</li>
                    </ul>
                </div>

                <div class="privacy-text">
                    <h6>5. Data Security and Retention</h6>
                    <p>We take reasonable measures to protect the information we collect from loss, misuse, and unauthorized access, disclosure, alteration, and destruction. However, no internet or email transmission is ever fully secure or error free, so
                        you should take special care in deciding what information you send to us. We will retain your personal information for as long as your account is active or as needed to provide you with our Services. We will retain and use your
                        information as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.</p>
                </div>

                <div class="privacy-text">
                    <h6>6. Children's Privacy</h6>
                    <p>Our Services are not directed to children under the age of 18, and we do not knowingly collect personal information from children under the age of 18. If we learn that we have collected personal information from a child under the age
                        of 18, we will delete that information as soon as possible.</p>
                </div>

                <div class="privacy-text">
                    <h6>7. International Data Transfer</h6>
                    <p>Your information may be transferred to, and maintained on, computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those in your jurisdiction. If
                        you are located outside the United States and choose to provide information to us, please note that we transfer the data, including personal information, to the United States and process it there. Your consent to this Privacy Policy
                        followed by your submission of such information represents your agreement to the transfer.</p>
                </div>

                <div class="privacy-text">
                    <h6>8. Links to Other Websites</h6>
                    <p>Our Services may contain links to other websites that are not operated by us. If you click on a third-party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you
                        visit. We have no control over and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.</p>
                </div>

                <div class="privacy-text">
                    <h6>9. Contact Us</h6>
                    <p>If you have any questions about this Privacy Policy, please contact us at <a href="mailto:info@upkeepable.com">info@upkeepable.com</a></p>
                </div>

                <br>
                <br>
                <br>

            </div>
        </div>
    </div>
</section>