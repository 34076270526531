import { Component,OnInit, HostListener, Renderer2, ViewChild, TemplateRef} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NavigationEnd, NavigationStart } from '@angular/router';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { CommonOpsService } from 'src/app/shared/common-ops-service';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
//import { NgbModal } from '@ng-bootstrap/ng-bootstrap'; import { MessageService } from './message.service';
import { AppHttpRequestHandlerService } from './shared/app-http-request-handler.service';
import { filter } from 'rxjs/operators';
import { UserJwtDecodedInfo } from './account/account-type-module';
import {EnumJsonTemplate, GenericFormModel, GenericResponseTemplateModel,GenericServiceResultTemplate, IState, TForm, INotification } from './generic-type-module';
import { AccountService } from './account/account.service';
import { takeUntil } from 'rxjs/operators';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ContacTypeModel } from './account/account-type-module';
import { AppToastService } from './toast/app-toast.service';
import Swal from 'sweetalert2';
import { SharedService } from 'src/app/shared.service';
import { IPriceEntry } from './home-owner-address/home-owner-address-type-module';
import  { NgForm } from "@angular/forms";
declare var $: any
declare var Stripe: any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

 isClassApplied = false;

  addClass() {
    this.isClassApplied = true;
    this.submitted = false;
  }

  removeClass() {
    this.isClassApplied = false;
  }

  
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  @ViewChild('qrCodeModalContent') qrCodeModalContent: TemplateRef<any>;
  @ViewChild('upgradeModalContent') upgradeModalContent: TemplateRef<any>;
  previousUrl: string = '';
  currentUrl: string = '';
  submitted = false;  
  hideFooter: boolean = false;
  title = 'UpKeepable';
  value = '';
  isMessage : boolean = false;
  isImage : boolean = false;
  image : string ='';
  formDataWithToken : any;
  cardMessage : string = '';
  isCardError : boolean = false;
  paymentMessage : string ='';
  isPaymentMessage: boolean = false;
  isPaymentMessageError: boolean = false;
  isPaymentSuccess : boolean = false;
  card: any;
  StripeKey: string = environment.StripeKey;
  stripe = Stripe(this.StripeKey);
  elements = this.stripe.elements();
   style = {
    base: {
      fontSize: '16px',
      color: '#32325d',
    },
  };
  isButtonText : boolean = false;
  isHide : boolean = false;
  modalBool : boolean = false;
  public subscription: Subscription;
  homeOwnerAddressId: number;
  defaultProfilePhoto: string = environment.defaultProfilePhoto;
  freeV: boolean = false;
  paidV : boolean = false;
  userJwtDecodedInfo: UserJwtDecodedInfo;
  menuCode: string='';
  currentURL: string;
  date = new Date();
  sub: Subscription;
  isNewHomeOwner: boolean=false;
  prevUserId:string=null;
  notifications: INotification[]=[];
  isShowNotificationClicked:boolean=false;
  hasLatestNotification:boolean=false;
  areREAMenuShown: boolean=true;
  isManageCard: boolean=false;
  inputForm1: TForm<IPriceEntry> = this.fb.group({
    Price_amount: [0, Validators.required]

}) as TForm<IPriceEntry>;
get formControls1() { return this.inputForm1.controls; }
defaultApiRoot = environment.defaultApiRoot;
  constructor(
    // private messageService: MessageService,    
    private modalService: NgbModal, 
    private sharedService: SharedService,
    private renderer: Renderer2, 
    public authService: AuthService,
    private accountService: AccountService,
    private fb: FormBuilder,    
    public commonOpsService: CommonOpsService,
    private appHttpRequestHandlerService: AppHttpRequestHandlerService,    
    public toastService: AppToastService,
    private router: Router,
    private route: ActivatedRoute) {
      this.router.events.subscribe(() => {
        // Check if current route includes HomeownerWithoutsponsorWelcomeComponent
        this.hideFooter = this.router.url.includes('HomeownerWithoutsponsorWelcomeComponent');
      });
      this.areREAMenuShown=true;
    // this.subscription = this.messageService.getHomeOwnerAddressId().subscribe(msg => { this.homeOwnerAddressId = msg })
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if(event.url.includes('Manage-Cards')){
          this.isManageCard=true;
        }

        if(document.getElementById('mobileMenuButton')){
          document.getElementById('mobileMenuButton').classList.add('collapsed')
        }
        

        if(this.authService.isUserLoggedIn()){
          this.getAllUnreadNotifications(this.authService.getUserJwtDecodedInfo().UserId);
        }

        if(event.url.includes('Dashboard') || event.url.includes('AllClients')){
          this.menuCode='Dashboard';
        }
        else if(event.url.includes('Details')){
          this.menuCode='Details';
        }
        else if(event.url.includes('Messages')){
          this.menuCode='Messages';
        }
        else if(event.url.includes('Analytics')){
          this.menuCode='Analytics'
        }
        else if(event.url.includes('home-variables-manager')){
          this.menuCode='home-variables-manager';
        }
        
        else{
          this.menuCode='';
        }
        if(window.location.href.includes('QRClientbyAgent')){
          this.router.navigate([window.location.href]);
        }

        if(event.url.includes('CompleteProfileSteps') || event.url.includes('success') || event.url.includes('canceled') || event.url.includes('Pick-A-Product')){
          this.areREAMenuShown=false;
        }

        //if (event.url.includes('Dashboard')) {

          // if(this.userJwtDecodedInfo?.RoleName=='REAL_ESTATE_USER'){
          //   this.menuCode='CL';
          // }
          // else if(this.userJwtDecodedInfo?.RoleName=='HOME_OWNER_USER'){
          //   this.menuCode='UpKeep';
          // }
          // else if(this.userJwtDecodedInfo?.RoleName=='ADMIN_USER'){
          //   this.menuCode='Dashboard';
          // }
          this.userJwtDecodedInfo = this.authService.getUserJwtDecodedInfo();         
          if (this.userJwtDecodedInfo) {
            if(this.userJwtDecodedInfo.RoleName=='ADMIN_USER'){
              this.defaultProfilePhoto = environment.defaultProfilePhoto; 
            }
            if(this.userJwtDecodedInfo.RoleName=='HOME_OWNER_USER'){
              this.appHttpRequestHandlerService.httpGet({ id: this.userJwtDecodedInfo.UserId}, "CommonApi", "GetPaidAndUnpaidHO")
                .pipe(takeUntil(this.ngUnsubscribe))
                  .subscribe((data: any) => {
                    if (data != null) {
                     this.freeV = data.freeV;
                     this.paidV = data.paidV;
                    }
                  });
            }
            else{
              if(this.userJwtDecodedInfo.UserImage!='' && this.userJwtDecodedInfo.UserImage!=null && this.isManageCard==false){
                this.appHttpRequestHandlerService.httpGet({ imageName: this.userJwtDecodedInfo.UserImage }, "CommonApi", "getImageBlob")
                .pipe(takeUntil(this.ngUnsubscribe))
                  .subscribe((data: any) => {
                    if (data != null) {
                      if(data.imageBase64==null || data.imageBase64.length==0){
                        this.defaultProfilePhoto = environment.defaultProfilePhoto;  
                      }
                      else{
                        this.defaultProfilePhoto = data.imageBase64;
                      }
                    }
                    else{
                      this.defaultProfilePhoto = environment.defaultProfilePhoto;
                    }
                  });
                } else {
                  this.defaultProfilePhoto = environment.defaultProfilePhoto;
                }
              } 
            }
        //}
      });
      this.renderer.listen('window', 'click', (event: Event) => {
        var classList = (<HTMLElement>event?.target)?.id;
        if(classList!='mobileMenuButton'){
          document.getElementById('mobileMenuButton')?.classList?.add('collapsed');
          
        }
      });
  }
  inputForm: TForm<ContacTypeModel> = this.fb.group({
    firstName: ['', [Validators.required ,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/), Validators.maxLength(20)]],
    lastName: ['', [Validators.required ,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/), Validators.maxLength(20)]],
    email: ['', [Validators.required, Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}')]],
    message: ['',[Validators.required]]
    }) as TForm<ContacTypeModel>;
    get formControls() { return this.inputForm.controls; }
    ngOnInit(): void {
      $(document).on("click", ".flip_modal_opener1", function () {
        $(".flip_modal_upper_wrapper1").addClass("show");
        $("body").css("overflow-y","hidden");
      });
      $(document).on("click", ".flip_btn", function () {
        $(".flip_modal_wrapper").addClass("flip safari");
      });
      $(document).on("click", "#flipnew", function () {

        $(".flip_modal_wrapper").removeClass("flip safari");
      });
  
      $(document).on("click", ".flip_modal_wrapper.flip button.btn-close", function () {
        $(".flip_modal_upper_wrapper1").removeClass("show");
        $("body").css("overflow-y","auto");
        $(".flip_modal_wrapper").removeClass("flip safari");
      });

      $(document).on("click", ".flip_modal_wrapper .btn-close", function () {
        $(".flip_modal_upper_wrapper1").removeClass("show");
        $("body").css("overflow-y","auto");
        
      });
      this.router.events.subscribe(event => {
        if (event instanceof NavigationStart) {
          // This is when the navigation starts
          //console.log('Navigating from:', this.currentUrl);
          this.previousUrl = this.currentUrl; // Store the current URL as the previous URL
        }
  
        if (event instanceof NavigationEnd) {
          // This is when navigation ends
          this.currentUrl = event.url; // Update the current URL to the new one
          //console.log('Navigated to:', this.currentUrl);
        }
      });
    }
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
   if(window.location.href.includes('Admin')&& window.location.href.includes('Dashboard')){
    if(this.prevUserId!=null){
      this.directLogin(this.prevUserId);
    }
   }
  }
  ngAfterContentInit() {
    this.sub = this.sharedService.send_data.subscribe(
      data => {
        if(data==true){
          if(localStorage.getItem("NewHomeOwner")=="true"){
             this.isNewHomeOwner = true;
          }
          else{
            this.isNewHomeOwner = false;
          }
        }
      }
    )
  }
  ngAfterViewInit(){
    this.prevUserId = localStorage.getItem('APID')
    // this.userJwtDecodedInfo = this.authService.getUserJwtDecodedInfo();
    // if(this.userJwtDecodedInfo?.RoleName=='REAL_ESTATE_USER'){
    //   this.menuCode='CL';
    // }
    // else if(this.userJwtDecodedInfo?.RoleName=='HOME_OWNER_USER'){
    //   this.menuCode='UpKeep';
    // }
    // else if(this.userJwtDecodedInfo?.RoleName=='ADMIN_USER'){
    //   this.menuCode='Dashboard';
    // }
  }
  getAllUnreadNotifications(userId){
    if(this.isManageCard==false){
      this.appHttpRequestHandlerService.httpGet({ id: userId }, "NotificationManager", "GetAllUnreadNotifications")
      .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data: GenericResponseTemplateModel<INotification[]>) => {
         this.notifications = data.responseDataModel;
         //if(this.notifications.length>0){
          this.isShowNotificationClicked=false;
          this.hasLatestNotification = this.notifications.some(x=>x.notificationStatusType==0);
         //}
        });
    }
    
  }
  onCloseAllModels() {
    this.modalService.dismissAll();
  }

  openModal(content) : void{
    this.modalBool = true;
  }
  openAddPriceModal(entryId:number, content, data:IPriceEntry): NgbModalRef {
    this.appHttpRequestHandlerService.httpGet({}, "CommonApi", "Price-Data").pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((data: any) => {
      if(data !== undefined){
        this.inputForm1.patchValue({
          Price_amount: data.imageBase64.price_Amount/100,
      });
    }
    });
    
    if(data === undefined){
      this.inputForm1.patchValue({
        Price_amount: 0,
    });
    }
    return this.modalService.open(content, {  size: 'lg', backdrop: 'static',keyboard: false });
  }

  saveEntry(){
    if (this.inputForm1.valid) {
      this.modalService.dismissAll();
      this.appHttpRequestHandlerService.httpPost(this.inputForm1.value, "CommonApi", "PriceSave").pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data: GenericServiceResultTemplate) => {
          this.toastService.show("","Submitted", 3000,"bg-success text-white","fa-check-circle");
        });
    }
  }

  onClickUpkeepNospon(menuCode: string) {
    this.menuCode=menuCode;
    this.modalService.dismissAll();
    localStorage.setItem('premiumvalue', JSON.stringify(true));
    this.router.navigate(['/HomeAddress/DashboardWithoutSpons']).then(() => {
      window.location.reload();
    });
  }

  onClickUpkeep1() {
    navigator.clipboard.writeText(this.value).then().catch(e => console.error(e));
    this.isButtonText = true;
    this.isMessage = true;
    this.isImage = true;
  }
  onModalCloseClick() {
    this.modalService.dismissAll();
  }

  onClickUpkeep(menuCode: string) {
    this.menuCode=menuCode;
    if(this.freeV === true || this.paidV === true)
    {
      this.router.navigate(['/HomeAddress/DashboardWithoutSpons']);
    }
    else{
      this.router.navigate(['/HomeAddress/Dashboard']);
    }
    
  }
  onClickHome(menuCode: string) {
    this.menuCode=menuCode;
    this.router.navigate(['/HomeAddress/Details']);
  }
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
    if (this.sub) {
      this.sub.unsubscribe()
    }
  }

  async onSubmitStripe(form: NgForm) {
    const { paymentMethod, error1 } = await this.stripe.createPaymentMethod({
      type: 'card',
      card: this.card,
      billing_details: {
          name: 'Jenny Rosen', // Customer’s name
      },
  });
        const {
            token,
            error
        } = await this.stripe.createToken(this.card);
        if (error) {
          this.isCardError = true;
          this.cardMessage = error.message;
          return;
      } 
        this.isCardError = false;
          this.cardMessage = '';
          this.formDataWithToken = {
            Email: this.userJwtDecodedInfo.EmailId,
            UserId : this.userJwtDecodedInfo.UserId,
            EmailToken : paymentMethod.id
          };
          this.appHttpRequestHandlerService.httpPost(this.formDataWithToken, "Payment", "CreatePaymentIntentForPaidHO").pipe(takeUntil(this.ngUnsubscribe))
              .subscribe((data: any) => {
                if(data.paymentmessage !== "Payment Failed" && data.paymentmessage !== "" && data.paymentmessage !== "something went wrong.")
                {
                  this.isPaymentMessage = true;
                  this.paymentMessage = data.paymentmessage;
                  this.card.clear();
                }
                else if(data.paymentmessage === "something went wrong.")
                {
                  this.paymentMessage = data.paymentmessage;
                  this.isPaymentMessageError = true;
                  this.card.clear();
                }
               else{
                this.paymentMessage = data.paymentmessage;
                this.isPaymentMessageError = true;
                this.card.clear();
               }
              });
      }


      goDashboard(){

      }

  openProfileManagerPage() {
    // if(this.userJwtDecodedInfo?.RoleName=='HOME_OWNER_USER' && this.authService?.getUserJwtDecodedInfo()?.IsAgree =='True'){
    //   this.router.navigate(['/Account/Settings']);
    // }
    // if(this.userJwtDecodedInfo?.RoleName=='REAL_ESTATE_USER' && this.authService?.getUserJwtDecodedInfo()?.IsAgree =='True'){
    //   this.router.navigate(['/Account/Settings']);
    // }
    // if(this.userJwtDecodedInfo?.RoleName=='ADMIN_USER'){
    //   this.router.navigate(['/Account/Settings']);
    // }
    var currentURL = window.location.href;  
    if(currentURL.split("/")[4]=="Settings")
      {
        if(this.userJwtDecodedInfo?.RoleName=='REAL_ESTATE_USER')
          {
            this.router.navigate(['/RealEstateAgent/Dashboard']);
          }
          if(this.userJwtDecodedInfo?.RoleName=='HOME_OWNER_USER')
            {
              this.router.navigate(['/HomeAddress/Dashboard']);
            }
            if(this.userJwtDecodedInfo?.RoleName=='ADMIN_USER')
              {
                this.router.navigate(['Admin/Dashboard']);
              }
        
      }
      else{
        this.router.navigate(['/Account/Settings']);
      }   
  }

  onClieckViewAllClients(){
    this.router.navigate(['/RealEstateAgent/AllClients']);
  }
  onClieckViewAllMessages(menuCode: string) {
    this.menuCode=menuCode;
    this.router.navigate(['/RealEstateAgent/Messages']);
  }
  onClieckViewAllAnalytics(menuCode: string) {
    this.menuCode=menuCode;
    this.router.navigate(['/RealEstateAgent/Analytics']);
  }
  onClieckAdminDashboard(menuCode: string) {
    this.menuCode=menuCode;
    this.router.navigate(['/Admin/Dashboard']);
  }
  onClieckHomeVariables(menuCode: string) {
    this.menuCode=menuCode;
    this.router.navigate(['/Admin/home-variables-manager']);
  }
  navigateToHome(){
    if(this.userJwtDecodedInfo?.RoleName=='HOME_OWNER_USER' && (this.userJwtDecodedInfo?.IsAgree =='True'|| this.authService.isPrevUserId()) && (!this.freeV && !this.paidV)){
      this.router.navigate(['/HomeAddress/Dashboard']);
    }
    else if(this.userJwtDecodedInfo?.RoleName=='HOME_OWNER_USER' && (this.userJwtDecodedInfo?.IsAgree =='True'|| this.authService.isPrevUserId()) && (this.freeV || this.paidV)){
      this.router.navigate(['/HomeAddress/DashboardWithoutSpons']);
    }
    else if(this.userJwtDecodedInfo?.RoleName=='REAL_ESTATE_USER' && (this.userJwtDecodedInfo?.IsAgree =='True'|| this.authService.isPrevUserId())){
      this.router.navigate(['/RealEstateAgent/Dashboard']);
    }
    else if(this.userJwtDecodedInfo?.RoleName=='ADMIN_USER'){
      this.router.navigate(['/Admin/Dashboard']);
    }
    if((this.userJwtDecodedInfo?.RoleName=='HOME_OWNER_USER' || this.userJwtDecodedInfo?.RoleName=='REAL_ESTATE_USER') && this.userJwtDecodedInfo?.IsAgree =='False' && (!this.authService.isPrevUserId())){
      this.goToLoginPage();
    }
  }
  openStaticPage(pageName:string){
    const rt = this.router.url.includes('myhome');
    if(rt === true)
    {
     localStorage.setItem("myHome", 'true');
    }
    else if (this.previousUrl.includes("") && (this.currentUrl.includes('/privacy') || this.currentUrl.includes('/legal'))) {
      localStorage.setItem("myHome", 'true');
    }
    else{
      localStorage.setItem("myHome", 'false');
    }
    this.router.navigate(['/'+pageName]);
      setTimeout(()=>{
        window.location.reload();
      }, 500);
    // else if(this.router.routerState.root.snapshot.firstChild.component["name"] == "QRClientAgentComponent"){
    //   this.router.navigate(['/'+pageName]);
    //   setTimeout(()=>{
    //     window.location.reload();
    //   }, 500);
    // }

  }
  openVideoModal(content) {
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title', centered:true }).result.then((result) => {}, (reason) => {});
  }
  onVideoModalCancelClick() {
    this.modalService.dismissAll();
  }
  onRequestClick(){
    this.modalService.dismissAll();
  }
  goToLoginPage(){
    if(localStorage.getItem("myHome") === 'true')
    {
      window.location.reload();
      this.router.navigate(['/myhome']);
    }
    else{
      this.router.navigate(['/Account/Login']);
    }
  }
  directLogin(userId){
    localStorage.removeItem('APID');
    
    this.appHttpRequestHandlerService.httpGet({"userId": userId}, "AccountManager", "DirectLoginByAdmin").pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((data: string) => {
      if ((<any>data).isAvailable) {
        this.setTokenAndSendUserToHomePage((<any>data).token);
      }
      else {
        //this.isSomethingWentWrong = true;
      }
    });
    //window.open('www.google.com', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes'); // Open new window
  }

  setTokenAndSendUserToHomePage(token: string) {
    localStorage.setItem("BearerToken", token);
    //window.location.reload();
    this.initiateHomePage();
  }
  initiateHomePage() {
   if (this.accountService.getUserJwtDecodedInfo().RoleName == 'ADMIN_USER') {
      this.router.navigate(['/Admin/']);
      setTimeout(()=>{
        window.location.reload();
      }, 500);
    } 
  }
  viewAllNotifications(){
    this.router.navigate(['all-notifications']);
  }
  mobileMenuButtonClick(event){

    var isAlreadyCollapsed = document.getElementById('mobileMenuButton')?.classList?.contains('collapsed');
    if(isAlreadyCollapsed){
      document.getElementById('mobileMenuButton')?.classList?.remove('collapsed')
    }
    else{
      document.getElementById('mobileMenuButton')?.classList?.add('collapsed')
    }
  }
  hasMobileMenuButtonHasClass(){
    return !document.getElementById('mobileMenuButton')?.classList?.contains('collapsed');
  }
  onClickDropNotification(){
    this.isShowNotificationClicked=true;
    this.hasLatestNotification=false;
    this.appHttpRequestHandlerService.httpGet({ id: this.authService.getUserJwtDecodedInfo().UserId }, "NotificationManager", "markReadAllNotifications")
    .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: GenericResponseTemplateModel<boolean[]>) => {});
  }
  onClicklinkDetails(){
    this.value = '';
    this.isMessage  = false;
    this.isImage  = false;
    this.image ='';
    this.isButtonText = false;
    this.isHide  = false;
    this.modalBool  = false;
    this.userJwtDecodedInfo = this.authService.getUserJwtDecodedInfo();
    if(this.userJwtDecodedInfo.RoleName === "HOME_OWNER_USER")
    {
      this.appHttpRequestHandlerService.httpGet({ Id: this.userJwtDecodedInfo.UserId}, "HomeOwnerAddress", "GetRealEstateUserDetails").pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: GenericServiceResultTemplate) => {
        let email =  data.email;
        this.image = data.image;
        let link = this.defaultApiRoot;
        let newUrl = link.replace(/:\d+/, '');
        this.value = newUrl.concat(email);
      });
    }
  }
  onClickupgradeDetails(content){
    this.userJwtDecodedInfo = this.authService.getUserJwtDecodedInfo();
    this.isCardError = false;
        if (this.card) {
      this.card.destroy();
    }
    setTimeout(() =>{
      this.card = this.elements.create('card',  this.style );
      this.card.mount('#card-element');
    },1500)
    this.modalService.open(this.upgradeModalContent, { size: 'lg', ariaLabelledBy: 'modal-basic-title', centered:true }).result.then((result) => {}, (reason) => {});
  }

  onSubmit() {
    if (!this.inputForm.valid) {
      this.submitted = true;
      return;      
    }
        this.appHttpRequestHandlerService.httpPost(this.inputForm.value, "CommonApi", "ContactForm").pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data: GenericServiceResultTemplate) => {
          this.isClassApplied = false;
          this.inputForm.reset();  
          Swal.fire({
            title: 'We have received your request',
            text: 'Your request has been submitted via email. Please check your inbox for updates',
            icon: 'success', // You can change this to 'success', 'error', 'info', etc.
            confirmButtonText: 'CLOSE'
            //showCloseButton: true
          });               
          //this.toastService.show("","Submitted", 3000,"bg-success text-white","fa-check-circle");
        });               
  }

}
