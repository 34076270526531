<div class="register-rea-form-group">
    <div class="modal-dialog modal-lg modal-dialog-centered">
        <div class="modal-content">
            <div class="row">
                <div class="col-md-12">
                    <div class="failed-modal-header">
                        <!-- <img src="assets/img/notification-icon.svg" class="" alt="check-icon-mail"> -->
                        <h4>Payment Cancelled</h4>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="success-email-text">
                        <h6>Your payment has cancelled</h6>
                    </div>
                </div>
                <!--  col-md-12   -->
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="register-modal-footer">
                        <button type="button" (click)="navigateToHome()" class="btn btn-primary">go to login</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="contact-modal-support">
                        <p>Need Help? <a href="https://help.upkeepable.com/" target="_blank">Contact support.</a></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>