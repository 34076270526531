import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharingDataService {
  invokeMyMethod = new EventEmitter();
  //HRService: boolean = false
  HRService: { value: boolean, month: string, year: number };


  constructor() { }

// setHrSharingData(value: boolean){
// this.HRService = value;
// }
setHrSharingData(value: boolean, month: string, year: number){
  this.HRService = { value, month, year };
}


getHrSharingData(){
  return this.HRService;
}

getMessageList(){
  this.invokeMyMethod.emit();
}
geAllHOEntry(){
  this.invokeMyMethod.emit();
}

}
