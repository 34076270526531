<div class="login_wrapper">
    <img src="assets/img/Untitled design.png" alt="login-img" class="login-img mobdnone">
    <div class="container-fluid px-0">
        <div class="row justify-content-end mx-0 px-0"> 
            
            <div class="col-12 col-md-6 left-side d-md-none px-0">
                <img src="assets/img/Untitled design.png" alt="login-img" class="img-fluid">
            </div>
            
            <!-- Right side with form -->
            <div class="col-12 col-md-6 right-side px-0">
                <div class="login-form">
                    <div class="login-heading">
                        <img src="assets/img/upkeepable-logo1.png" alt="home-logo" class="img-fluid">
                    </div>
                    <div class="text-center mt-3">
                        <h4>Home Owner Login</h4>
                        <p>Log into your account</p>
                    </div>
                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <!-- Email input -->
                        <div class="form-outline">
                            <label class="form-label" for="form2Example1">Email address</label>
                            <input type="email" id="form2Example1" placeholder="Enter Your Email" (focus)="resetErrorMsg()" formControlName="userName" class="form-control">
                        </div>
    
                        <!-- Password input -->
                        <div class="form-outline">
                            <label class="form-label" for="form2Example2">Password</label>
                            <div class="loginConfirmNewPass">
                                <input [type]="fieldPass ? 'text' : 'password'" id="form2Example2" (focus)="resetErrorMsg()" placeholder="Enter Your Password" (focus)='onPasswordFocusIn()' formControlName="password" class="form-control">
                                <div class="input-group-append">
                                    <span class="input-group-text">
                                        <i class="fa" [ngClass]="{ 'fa-eye-slash': !fieldPass, 'fa-eye': fieldPass }" (click)="togglePass()"></i>
                                    </span>
                                </div>
                            </div>
                            <span class="text-danger" *ngIf="!IsREA && isloginError && !isLoginRestricted">Invalid username or password..!</span>
                            <span class="text-danger" style="padding: 10px 0; display: inline-block; text-align: center;" *ngIf="isloginError && isLoginRestricted && !IsREA">{{restrictedReasonMessage}} Please contact us at <a href="mailto:help@upkeepable.com">help@upkeepable.com</a></span>
                            <span class="text-danger" style="padding: 10px 0; display: inline-block; text-align: center;" *ngIf="isloginError && isLoginRestricted && IsREA">{{restrictedReasonMessage}} Please contact us at <a href="mailto:help@upkeepable.com">help@upkeepable.com</a></span>
                        </div>
    
                        <!-- 2 column grid layout for inline styling -->
                        <div class="row">
                            <div class="col-6">
                                <!-- Checkbox -->
                                <div class="form-check">
                                    <div class="checkbox-group">
                                        <input type="checkbox" (change)="onRemberMeChange($event)" [checked]="rememberme" id="remember">
                                        <label for="remember">Remember me</label>
                                    </div>
                                </div>
                            </div>
    
                            <div class="col-6 text-end">
                                <a (click)="onClickForgetPassword()" class="forgot-username">Forgot Password?</a>
                            </div>
                        </div>
    
                        <div class="row justify-content-center ">
                            <div class="col-lg-6 col-md-8 col-12 text-center">
                                <!-- Submit button -->
                                <button type="submit" class="btn btn-primary btn-block mb-4">Sign in</button>
                            </div>
                            <div class="col-lg-12 text-center mt-4 ">
                                <a href="#" (click)="goToLoginPage()" style="color: var(--0061C1-title-color);">Login as Sponsor</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    
</div>
