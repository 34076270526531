<div class="login_wrapper">
    <div class="register-rea-form-group">
        <div class="modal-dialog modal-lg modal-dialog-centered">
            <div class="modal-content">
                <div class="row">
                    <div class="col-md-12">
                        <div class="success-modal-header">
                            <img src="assets/img/check-icon-mail.svg" class="" alt="check-icon-mail">
                            <h4> Card Added Successfully</h4>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="success-email-text">
                            <h6>Your Payment was successfully! <br>You can now continue using Upkeepable</h6>
                        </div>
                    </div>
                    <!--  col-md-12   -->
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="register-modal-footer">
                            <button type="button" (click)="navigateToHome()" class="btn btn-primary">go to dashboard</button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="contact-modal-support">
                            <p>Need Help? <a href="https://help.upkeepable.com/" target="_blank">Contact support.</a></p>
    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
