<section class="demo-text-section">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12">

                <div class="privacy-text">
                    <h2>Legal Disclaimers</h2>
                    <h6>1. Disclaimer of Warranties</h6>
                    <p>The information and materials contained in this website are provided "as is" and without warranties of any kind, either express or implied. Upkeepable ("we", "us", "our") disclaims all warranties, express or implied, including, but
                        not limited to, implied warranties of merchantability, fitness for a particular purpose, and non-infringement. We do not warrant that the functions contained in the materials will be uninterrupted or error-free, that defects will
                        be corrected, or that this website or the server that makes it available are free of viruses or other harmful components. We do not warrant or make any representations regarding the use or the results of the use of the materials
                        on this website in terms of their correctness, accuracy, reliability, or otherwise. You assume the entire cost of all necessary servicing, repair, or correction.</p>

                </div>

                <div class="privacy-text">
                    <h6>2. Limitation of Liability</h6>
                    <p>Under no circumstances, including, but not limited to, negligence, shall we be liable for any special or consequential damages that result from the use of, or the inability to use, the materials on this website. In no event shall our
                        total liability to you for all damages, losses, and causes of action (whether in contract, tort, or otherwise) exceed the amount paid by you, if any, for accessing this website.</p>
                </div>

                <div class="privacy-text">
                    <h6>3. Indemnification</h6>
                    <p>You agree to indemnify and hold us, and our affiliates, officers, agents, and other partners and employees, harmless from any loss, liability, claim, or demand, including reasonable attorneys' fees, made by any third party due to or
                        arising out of your use of the Services in violation of this Agreement and/or arising from a breach of this Agreement and/or any breach of your representations and warranties set forth above.</p>
                </div>

                <div class="privacy-text">
                    <h6>4. Governing Law</h6>
                    <p>This website is controlled and operated by us from our offices within the United States. We make no representation that the materials on this website are appropriate or available for use in other locations. Those who choose to access
                        this website from other locations do so on their own initiative and are responsible for compliance with local laws, if and to the extent local laws are applicable. This Agreement shall be governed by and construed in accordance
                        with the laws of the State of North Carolina, without giving effect to any principles of conflicts of law. You agree that any action at law or in equity arising out of or relating to these terms shall be filed only in the state
                        and federal courts located in Raleigh, North Carolina and you hereby consent and submit to the personal jurisdiction of such courts for the purposes of litigating any such action.</p>
                </div>

                <div class="privacy-text">
                    <h6>5. Intellectual Property</h6>
                    <p>The materials contained in this website are protected by copyright and trademark laws. You may not use any of the materials on this website for any commercial purpose without the express written consent of us. Unauthorized use of the
                        materials on this website may violate copyright, trademark, and other laws.</p>
                </div>

                <div class="privacy-text">
                    <h6>6. Modification of Materials and Services</h6>
                    <p>We reserve the right to modify or discontinue the Services or any portion thereof with or without notice to you. We shall not be liable to you or any third party should we exercise our right to modify or discontinue the Services.
                    </p>
                </div>

                <div class="privacy-text">
                    <h6>7. Entire Agreement</h6>
                    <p>This Agreement constitutes the entire agreement between you and us and governs your use of the Services, superseding any prior agreements between you and us. You may also be subject to additional terms and conditions that may apply
                        when you use third-party content or services.</p>
                </div>

                <div class="privacy-text">
                    <h6>8. Waiver</h6>
                    <p>The failure of us to exercise or enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision. If any provision of this Agreement is found by a court of competent jurisdiction to be invalid,
                        the parties nevertheless agree that the court should endeavor to give effect to the parties' intentions as reflected in the provision, and the other provisions of this Agreement remain in full force and effect.</p>
                </div>

                <div class="privacy-text">
                    <h6>9. Statute of Limitations</h6>
                    <p>You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to the use of the Services or this Agreement must be filed within one (1) year after such claim or cause of action
                        arose or be forever barred.</p>
                </div>

                <div class="privacy-text">
                    <h6>10. No Third-Party Beneficiaries</h6>
                    <p>You agree that, except as otherwise expressly provided in this Agreement, there shall be no third-party beneficiaries to this Agreement. Effective date: 12/1/2022</p>
                </div>

                <br>
                <br>
                <br>


            </div>
        </div>
    </div>
</section>