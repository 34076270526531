
<form [formGroup]="EmailReplyForm" (ngSubmit)="onSubmit()">
<div class="reply_mail_wrapper">
    <div class="bg-white dark:bg-zinc-900 p-4 rounded-lg shadow-md max-w-4xl mx-auto my-3">
        <div class="reply_header flex items-center space-x-2 mb-4">
          <button class="bg-zinc-300 dark:bg-zinc-700 text-zinc-700 dark:text-zinc-300 px-4 py-2 rounded" type="submit" >Send</button>
          <button class="bg-zinc-200 dark:bg-zinc-700 text-zinc-700 dark:text-zinc-300 px-4 py-2 rounded">Cancel</button>
       
        </div>
        <div class="mb-4 d-flex">
          <label class="block text-zinc-700 dark:text-zinc-300" for="queryParamLabel">To:</label>
          <span id="queryParamLabel">{{ queryParamValue }}</span>
          <!-- <input formControlName="To" type="text" class="form-control"> -->
        </div>
        <div class="mb-4 d-flex">
          <label class="block text-zinc-700 dark:text-zinc-300">Subject:</label>
          <input  type="text" formControlName="Subject" class="form-control">
        </div>
        <div class="mb-4">
            <label class="block text-zinc-700 dark:text-zinc-300 mb-3">Message:</label>
          <textarea formControlName="Message" class="form-control" placeholder="Type Message"></textarea>
        </div>
      </div>
</div>
</form>