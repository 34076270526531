<!-- <input type="file" (change)="fileChangeEvent($event)" /> -->

<!-- <div class="modal-header">
</div> -->
<div class="modal-body ho-profile-crop">
    <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="false" [aspectRatio]="aspectRatio" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()">
    </image-cropper>

</div>

<div class="text-danger img-size-error" *ngIf="invalidSize">
    Maximum File Size Limit is 1MB
</div>

<div class="modal-footer">

    <button type="button" class="btn btn-secondary" (click)="onModelNoClick()" data-bs-dismiss="modal">Cancel</button>
    <button type="button" (click)="!submitted && onImageCropDone()" class="btn btn-primary">Crop Image</button>

</div>

<!-- <button (click)="passCroppedImageToParent()"></button> -->


<!-- <img [src]="croppedImage" /> -->