import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    if(localStorage.getItem("myHome") === 'true')
    {
      localStorage.setItem("myHome", 'true');
    }
    else{
      localStorage.setItem("myHome", 'false');
    }
  }

}
