import { Component, ElementRef , Injectable, Input, OnDestroy, OnInit, TemplateRef, ViewChild, Renderer2, HostListener, ChangeDetectorRef   } from '@angular/core';
import { NgbModal, NgbModalConfig, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AppHttpRequestHandlerService } from 'src/app/shared/app-http-request-handler.service';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import {EnumJsonTemplate, GenericFormModel, GenericResponseTemplateModel, IState, TForm } from 'src/app/generic-type-module';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IHomeOwnerNewUserResponseViewModel, IHomeOwnerProfileViewModel } from 'src/app/home-owner-address/home-owner-address-type-module';
import { CommonOpsService } from 'src/app/shared/common-ops-service';
import {Router, ActivatedRoute, CanDeactivate} from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { AccountService } from 'src/app/account/account.service';
import { AppToastService } from '../../toast/app-toast.service';
import { IRealEstateAgentViewModel } from 'src/app/real-estate-agent/real-estate-agent-type-module';
import { environment } from 'src/environments/environment';
import { SharedService } from 'src/app/shared.service';
import { IHomeVariableMainCategory, IHomeVariableSubCategory } from 'src/app/admin-user/home-variable-manager/home-variable-type-module';
import { CookieService } from 'ngx-cookie';
import { LoginTypeModel } from 'src/app/account/account-type-module';
declare var $: any
declare var Stripe: any;

@Component({
  selector: 'app-homeowner-register-withoutsponsor',
  templateUrl: './homeowner-register-withoutsponsor.component.html',
  styleUrls: ['./homeowner-register-withoutsponsor.component.css']
})
export class HomeownerRegisterWithoutsponsorComponent implements OnInit {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  submitted = false;
  cardMessage : string = '';
  isLogin: boolean = false;
  freeV: boolean = false;
  paidV: boolean = false;
  isMobile: boolean = false;
  public isloginError: boolean= false;
  isLoginRestricted: boolean= false;
  restrictedReasonMessage:string= ""; 
  selectedPlan: string = 'premium';
  isCardError: boolean = false;
  couponCode: string = '';
  isMessage: boolean = false;
  isPrice: boolean = false;
  message: string = '';
  amountpayable: number = 0;
  StripeKey: string = environment.StripeKey;
  stripe = Stripe(this.StripeKey);
  elements = this.stripe.elements();
   style = {
    base: {
      fontSize: '16px',
      color: '#32325d',
    },
  };
  card:any;
  cardNumber: any;
  cardExpiry: any;
  cardCvc: any;
  error: string = '';
  @ViewChild('cropImageModal') cropImageModal: TemplateRef<any>;
  @ViewChild('contentDiv') contentDiv!: ElementRef;  // Reference to the div
  divError: boolean = false;
  premiumSelected: boolean = false;  // Track whether "SELECT" was clicked
  fieldOldPass: boolean;
  fieldNewPass: boolean;
  basicSelected:boolean = false;
  tokenValue: string = '';
  fileUploadEventObject: any;
  targetImageElementId: string = '';
  date = new Date();
  aspectRatio: string = '1/1';
  pageAdditionalInfo: IRealEstateAgentViewModel;
  messageHomePhoto: string = '';
  homePhoto: string = null;
  userId: number;  
  getemail:string;
  value = ''; 
  states: IState[]=[];
  homeTypeEnum: EnumJsonTemplate[] = [];
  enumListTemplateLists: any;
  homeType:any;  
  isLoad: number = 1;
  homeAddressGeneralDetailId: number=0;
  mainCategories:IHomeVariableMainCategory[]=[];
  private cropModalReference: NgbModalRef;
  isCompleteHomeProfile: boolean = false;
  isDuplicateEmail: boolean=false;
  selCollapseBtn: string='collapseOne';
  defaultProfilePhoto: string = environment.defaultProfilePhoto;
  profilePhoto: string = this.defaultProfilePhoto;

  //QrcodeUrl: any;

  inputForm: TForm<IHomeOwnerProfileViewModel> = this.fb.group({
    homeAddressGeneralDetailId: [0, Validators.required],
    homeType: [0],
    homeOwnerAddressId: [0, Validators.required],
    address: ['', [Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/), Validators.maxLength(40)]],
    zipCode: ['', [Validators.pattern('[0-9]{5}')]],
    isNotficationOn: [0, [Validators.required]],
    homeProfileImage: [""],
    userRefId: [0, [Validators.required]],
    cityName: ['', [Validators.pattern(/^[a-zA-Z ]*$/), Validators.maxLength(20)]],
    stateCode: [''],
    firstName: ['', [Validators.required ,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/), Validators.maxLength(20)]],
    lastName: ['', [Validators.required ,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/), Validators.maxLength(20)]],
    email: ['', [Validators.required, Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}')]],
    phone: ['', [Validators.pattern('^(\\+?\d{1,4}[\s-])?(?!0+\s+,?$)\\d{10}\s*,?$')]],
    password: ['', [Validators.required ,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/),Validators.minLength(6)]],
    repeatPassword: ['', [Validators.required ,Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
    userImage: [''],
    userImageBase64: [''],
    homeProfileImageBase64: [''],
    realEstateAgentUserRefId: [''],
    isShareable:false,
    userId:[0],
    premiumSelected:true,
    basicSelected:false,
    tokenValue :[''],
    couponCode:'',
    amountpayable: ''
  }, { validator: this.passwordMatchValidator }) as TForm<IHomeOwnerProfileViewModel>;
  loginForm: TForm<LoginTypeModel> = this.fb.group({
    UserName: [''],
    Password: [''],
    Recaptcha: [''],
    ForgetType: ['']
  }) as TForm<LoginTypeModel>;
  get InviteClientformControls() { return this.inputForm.controls; }
  subCategories:IHomeVariableSubCategory[]=[];

  @Input() mainCategory: IHomeVariableMainCategory;


  constructor(private modalService: NgbModal,
    config: NgbModalConfig,
    private sharedService: SharedService,
    private appHttpRequestHandlerService: AppHttpRequestHandlerService,
    private fb: FormBuilder,
    public commonOpsService: CommonOpsService,
    private activatedRoute: ActivatedRoute,
    private location: LocationStrategy,
    public toastService: AppToastService,
    private router: Router,
    private accountService: AccountService,
    private cookieService: CookieService,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
     ) {
    config.backdrop = 'static';
    config.keyboard = false;
    history.pushState(null, null, window.location.href);  
    this.location.onPopState(() => {
    this.modalService.dismissAll();
    history.pushState(null, null, window.location.href);   
    }); 

    $(document).ready(function(){
      document.getElementById('et_pb_video_overlay').addEventListener('click', function () {
        this.classList.add("hide");
        const videoId = 'C1APWZYa3jg';
        const iframe = document.createElement('iframe');
        iframe.style.width = "100%";
        iframe.style.height = "100%";
        iframe.style.position = "absolute";
        iframe.style.top = "0";
        iframe.setAttribute('src', `https://www.youtube.com/embed/${videoId}?autoplay=1`);
        iframe.setAttribute('frameborder', '0');
        iframe.setAttribute('allow', 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture');
        iframe.setAttribute('allowfullscreen', '');
    
        const videoContainer = document.getElementById('iframe_container');
        videoContainer.innerHTML = '';
        videoContainer.appendChild(iframe);
      });
    });

  }

 
  toggleCollapse(collapseBtn: string){
     var allCollapsibles = document.querySelectorAll(".accordion-collapse");
     var isAlreadyCollapsed = document.getElementById(collapseBtn).classList.contains('collapse');
 
     allCollapsibles.forEach(element => {
       if (collapseBtn == element.id && isAlreadyCollapsed){
         document.getElementById(collapseBtn).classList.remove('collapse')
       }
       else if (collapseBtn == element.id && !isAlreadyCollapsed){
         document.getElementById(collapseBtn).classList.add('collapse')
       }
       else{
         document.getElementById(element.id).classList.add('collapse')
       }
     });
   }

   isCollapsedShown(collapseId: string){
    return !document.getElementById(collapseId).classList.contains('collapse')
  }

  
  ngOnInit() {
    this.isMobile = window.innerWidth <= 768; // Example for mobile detection
    window.addEventListener('resize', () => {
      this.isMobile = window.innerWidth <= 768;
    });
    window.scroll({ 
      top: 0, 
      left: 0, 
      behavior: 'smooth'
    });
    this.cdr.detectChanges();
    this.activatedRoute.params.subscribe(params => {     
    
      this.getemail = params['id'];
    });
 
  
  //      this.appHttpRequestHandlerService.httpPost({EmailId : this.getemail}, "AccountManager", "GetUserDetails").pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe((data: any) => {
       
  //     const userId = data.data.userId;
  //     this.userId = data.data.userId;
  //     this.inputForm.patchValue({
  //             userId: data.data.userId
  //            });
  

  // this.appHttpRequestHandlerService.httpGet({ id: this.userId }, "RealEstateAgent", "GetRealEstateAgentProfileById").pipe(takeUntil(this.ngUnsubscribe))
  // .subscribe((data: GenericFormModel<IRealEstateAgentViewModel>) => {
   
  //   this.pageAdditionalInfo = data.formModel; 
  //   this.enumListTemplateLists = data.enumListTemplateLists;
  //   if (data.formModel.userImageBase64 != null) {
  //     this.profilePhoto = data.formModel.userImageBase64;
  //   }
       
  // });

  //   });


    this.getAllSubCategories(1);   
    localStorage.removeItem("Bea  rerToken");
    localStorage.clear();
    localStorage.setItem("NewHomeOwner", "true");
    localStorage.setItem("isLoad", "1");
    //console.log("qr",localStorage.getItem("NewHomeOwner"));
    this.sharedService.send_data.next(true);
    document.getElementById('collapseOne').classList.add('collapse');
    // this.activatedRoute.queryParams.subscribe(params => {
    // this.userId = params['foo'];
    // });
    this.appHttpRequestHandlerService.httpGet({ id: 0}, "CommonApi", "GetAllStatesList").pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: IState[]) => {
        this.states = data;
      });
    
      setTimeout(() => {
        this.getAllMainCategories();
      }, 500);
      if (typeof window !== 'undefined') {
        setTimeout(() =>{
          this.card = this.elements.create('card',  this.style );
          this.card.mount('#card-element');
      //     this.cardNumber = this.elements.create('cardNumber');
      // this.cardExpiry = this.elements.create('cardExpiry');
      // this.cardCvc = this.elements.create('cardCvc');

      // Mount the elements to their respective divs
      // this.cardNumber.mount('#card-number-element');
      // this.cardExpiry.mount('#card-expiry-element');
      // this.cardCvc.mount('#card-cvc-element');
        },1500)
         // if (this.card) {
    //   this.card.destroy();
    // }
      }
   if(this.isMobile)
   {
   this.premiumSelected = true;
   }
    if(!this.isMobile)
    {
    this.premiumSelected = true;
    this.basicSelected = false;
    this.selectedPlan = 'premium';
     this.divError = false;  // Reset any previous errors
    const button = document.getElementById('select-premium-btn');
    const div = document.getElementById('premiumDiv');
    if (button && !this.isMobile) {
      this.renderer.setProperty(button, 'innerText', 'Selected'); // Change button text
      this.renderer.removeClass(button, 'btn-secondary');
      this.renderer.addClass(button, 'btn-primary');
    }
    if (div) {
      this.renderer.setStyle(div, 'border', '4px solid  var(--0061C1-title-color)');  // Apply border
    }
    }
  }

 @HostListener('window:resize', ['$event'])
  onResize() {
    this.checkScreenSize();
  }

  checkScreenSize() {
    this.isMobile = window.innerWidth < 768;
  }
  passwordMatchValidator(frm: FormGroup) {
    return frm.value.password === frm.value.repeatPassword ? null : { 'mismatch': true };
  }
  selectPremium() {
    this.premiumSelected = true;
    this.basicSelected = false;
    this.selectedPlan = 'premium';
    this.divError = false;  // Reset any previous errors
    const button = document.getElementById('select-premium-btn');
    const div = document.getElementById('premiumDiv');
    if (button && !this.isMobile) {
      this.renderer.setProperty(button, 'innerText', 'Selected'); // Change button text
    }
    if (div) {
      this.renderer.setStyle(div, 'border', '4px solid  var(--0061C1-title-color)');  // Apply border
    }
    const button1 = document.getElementById('select-basic-btn');
    const div1 = document.getElementById('basicDiv');
    if (button1) {
      this.renderer.setProperty(button1, 'innerText', 'Select'); // Change button text
    }
    if (div1) {
      this.renderer.removeStyle(div1, 'border');  // Removes the border style
    }
  }

  selectBasic() {
    this.card.clear();
    this.basicSelected = true;
    this.premiumSelected = false;
    this.divError = false;  // Reset any previous errors
    this.selectedPlan = 'basic';
    const button = document.getElementById('select-basic-btn');
    const div = document.getElementById('basicDiv');
    if (button && !this.isMobile) {
      this.renderer.setProperty(button, 'innerText', 'Selected'); // Change button text
    }
    if (div) {
      this.renderer.setStyle(div, 'border', '4px solid  var(--0061C1-title-color)');  // Apply border
    }
    const button1 = document.getElementById('select-premium-btn');
    const div1 = document.getElementById('premiumDiv');
    if (button1) {
      this.renderer.setProperty(button1, 'innerText', 'Select'); // Change button text
    }
    if (div1) {
      this.renderer.removeStyle(div1, 'border');  // Removes the border style
    }
  }
  onInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.couponCode = inputElement.value;
    this.appHttpRequestHandlerService.httpGet({ id:this.couponCode }, "CommonApi", "CouponDetailsForHO").pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: any) => {
        //console.log('logdata', data);
        if(data.message === "Coupon not found.")
        {
          this.isMessage = true;
            this.message = data.message;
          this.amountpayable = 99;
          this.couponCode = "";
        }
        else if(data.message !== undefined)
        {
          this.isMessage = true;
          this.message = data.message;
        }
        else{
          this.isPrice = true;
          this.amountpayable = data.price;
          if(data.price < 0)
          {
            this.amountpayable = 0.5
          }
          if(this.amountpayable !== null || this.amountpayable !== 0)
            {
              this.couponCode = data.stripe_CouponCode;
            }
        }
        
      });
  }

  getAllMainCategories(){
    this.appHttpRequestHandlerService.httpGet({ id: 0 }, "HomeVariableManager", "GetAllMainCategoriesWithAllChilds").pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((data: GenericResponseTemplateModel<IHomeVariableMainCategory[]>) => {
      this.mainCategories=data?.responseDataModel;
      setTimeout(() => {
      this.enumListTemplateLists =this.mainCategories[0]?.homeVariableSubCategories[0]?.homeVariableSubCategoryListItems.filter(x=>x.homeVariableSubCategoryRefId == 1 && !x.isDeleted);
    }, 500);
    });
  }

  onSubmitWithskipHomeDetails(){
    this.isCompleteHomeProfile=false;
    this.onSubmit();
  }
  scroll(el: HTMLElement) {
    el.scrollIntoView({behavior: 'smooth'});
  }

  getValues(){
    this.appHttpRequestHandlerService.httpGet({ homeAddressGeneralDetailId: this.homeAddressGeneralDetailId }, "HomeOwnerAddress", "GetHomeAddressGeneraldetail").pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((data: GenericFormModel<IHomeOwnerProfileViewModel>) => {      
      this.homeTypeEnum = this.commonOpsService.getEnumItemsByEnumName(data.enumListTemplateLists, "HomeTypeEnum");
     
    }); 

  }

  getAllSubCategories(id: number){
    if(id){
    this.appHttpRequestHandlerService.httpGet({ id: id }, "HomeVariableManager", "GetAllSubCategoriesByMainCategoryId").pipe(takeUntil(this.ngUnsubscribe))
    .subscribe((data: GenericResponseTemplateModel<IHomeVariableSubCategory[]>) => {       
      this.subCategories=data.responseDataModel;
      this.getValues();
    });
  }
}

async onSubmit() {
    if(this.premiumSelected === true)
    {
      const { paymentMethod, error1 } = await this.stripe.createPaymentMethod({
        type: 'card',
        card: this.card,
        billing_details: {
            name: 'Jenny Rosen', // Customer’s name
        },
    });
      const {
        token,
        error
    } = await this.stripe.createToken(this.card);
    if (error) {
      this.isCardError = true;
      this.cardMessage = error.message;
      return;
  }
  if (token) {
    this.tokenValue = paymentMethod.id;
}
    }
    
    if (!this.premiumSelected && !this.basicSelected) {
      this.divError = true;  // Show error if neither is selected
    } else {
      this.divError = false;  // No error if at least one is selected
    }
    this.inputForm.controls.isShareable.value= false;
    this.inputForm.controls.premiumSelected.patchValue(this.premiumSelected);
    this.inputForm.controls.basicSelected.patchValue(this.basicSelected);
    this.inputForm.controls.couponCode.patchValue(this.couponCode);
    this.inputForm.controls.amountpayable.patchValue(this.amountpayable);
    if(this.inputForm.controls.homeType?.value == null || this.inputForm.controls.homeType?.value == 0)
      {
        this.inputForm.patchValue({
           homeType: this.subCategories[0]?.homeVariableSubCategoryListItems[0]?.homeVariableSubCategoryListItemId,
         })
      }
    this.inputForm.controls.realEstateAgentUserRefId.patchValue(this.userId);
    this.inputForm.controls.tokenValue.patchValue(this.tokenValue);
    if(this.inputForm.controls.cityName?.value?.trim()?.length  == 0){
      this.inputForm.controls.cityName?.patchValue("");
    }
    if (this.inputForm.valid && !this.isDuplicateEmail && !this.divError) {
      
      this.appHttpRequestHandlerService.httpPost(this.inputForm.value, "RealEstateAgent", "InviteNewHomeOwnerWithoutSponsor").pipe(takeUntil(this.ngUnsubscribe))
        .subscribe((data: GenericFormModel<IHomeOwnerNewUserResponseViewModel>) => {
          //console.log('logdata', data)
          if(data.formModel.isSuccefullyCreated === true)
          {
            this.loginForm.controls.UserName.patchValue(this.inputForm.controls.email.value);
            this.loginForm.controls.Password.patchValue(this.inputForm.controls.password.value);
            this.loginForm.controls.Recaptcha.patchValue(null);
            this.loginForm.controls.ForgetType.patchValue(null);
            this.appHttpRequestHandlerService.httpPost(this.loginForm.value, "AccountManager", "login").pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((data: string) => {
              if ((<any>data).isAvailable) {
                localStorage.setItem('firstTimeLogin', '1');        
                this.setTokenAndSendUserToHomePage((<any>data).token);
              }
              else {
                //IsLoginRestricted=true, RestrictedReasonMessage
                this.isloginError = true;
                if((<any>data).token ==  ''){
                  this.isLoginRestricted=true;
                  this.restrictedReasonMessage = "Invalid username or password..! ";
                }
                if ((<any>data).isLoginRestricted) {
                  this.isLoginRestricted=true;
                  this.restrictedReasonMessage = "Your account has been " + (<any>data).restrictedReasonMessage + ". " ;
                 
                }
              }
            });
          }
          //this.toastService.show("","Homeowner added successfully", 3000,"bg-success text-white","fa-check-circle");
         
          //this.router.navigate(['/Account/TermsAndConditions']);
          //this.router.navigate(['/HomeAddress/DashboardWithoutSpons'],{queryParams: {dl:"Y" }});
         //this.router.navigate(['/Account/HomeownerWithoutsponsorWelcomeComponent'], { queryParams: { info: this.inputForm.controls.email.value, homwownerId: data.formModel.homeOwnerAddressId,  foo: this.userId}});
        });
    }
    this.submitted = true;
    this.isMessage = false;
    this.message ="";
  }

  setTokenAndSendUserToHomePage(token: string) {
    debugger
    localStorage.setItem("BearerToken", token);
    this.initiateHomePage();
  }
  initiateHomePage() {
    debugger
    if (this.accountService.getUserJwtDecodedInfo().RoleName == 'HOME_OWNER_USER') {
      //  if(this.accountService.getUserJwtDecodedInfo().IsAgree=='True'){
      //   this.appHttpRequestHandlerService.httpGet({ id: this.accountService.getUserJwtDecodedInfo().UserId}, "CommonApi", "GetPaidAndUnpaidHO").pipe(takeUntil(this.ngUnsubscribe))
      //   .subscribe((data: any) => {
      //     console.log('dataforimg1', data)
      //    this.freeV = data.freeV;
      //    this.paidV = data.paidV;
      //    if (this.freeV || this.paidV) {
      //      if(this.freeV)
      //      {
      //       this.value = 'Basic';
      //      }
      //      else{
      //        this.value = 'Premium';
      //      }
      //    this.appHttpRequestHandlerService.httpGet({ value: this.value, Id:this.accountService.getUserJwtDecodedInfo().UserId}, "CommonApi", "GetHoWithNOSponsMessage").pipe(takeUntil(this.ngUnsubscribe))
      //    .subscribe((data: any) => {
      //     debugger
      //      if(data.message !== "")
      //      {
      //        if(data.dataPopup !== null)
      //        {
      //        this.isLogin = data.dataPopup.isLogin;
      //        if (this.isLogin === true) {
      //          localStorage.setItem('loginvalue', JSON.stringify(true));  // Save 'true' in localStorage
      //        } 
      //        else{
      //         localStorage.setItem('loginvalue', JSON.stringify(false));
      //        }
      //        }
      //        this.isLogin = JSON.parse(localStorage.getItem('loginvalue'));
      //        this.router.navigate(['/HomeAddress/DashboardWithoutSpons'],{queryParams: {dl:"Y" }});
      //      }
      //      else{
      //       this.isLogin = JSON.parse(localStorage.getItem('loginvalue'));
      //        this.router.navigate(['/HomeAddress/DashboardWithoutSpons'],{queryParams: {dl:"Y" }});
      //      }

      //    });
        
      //  }
      //   });
      
      //  }
       if(this.accountService.getUserJwtDecodedInfo().IsAgree=='False'){
        this.isLogin = JSON.parse(localStorage.getItem('loginvalue'));
        this.router.navigate(['/Account/TermsAndConditions']);
       }
       
      //});
    } 
  }
  passClickToHomeProfileFileInput(browserInputId: string) {
    document.getElementById(browserInputId).click();
  }

  openVideoModal(content) {
    this.modalService.open(content, { size: 'lg', ariaLabelledBy: 'modal-basic-title', centered:true }).result.then((result) => {}, (reason) => {});
  }
  onVideoModalCancelClick() {
    this.modalService.dismissAll();
  }
  onHomeProfilePhotoBrowserInputChange(event, targetImageElementId, aspectRatio) {
    if (event.target.value.length) {
      this.fileUploadEventObject = event;
      this.targetImageElementId = targetImageElementId;
      this.aspectRatio = aspectRatio;
      this.cropModalReference  = this.openCropImageModal(this.cropImageModal);
    }
  }
  openCropImageModal(content) : NgbModalRef{
    return this.modalService.open(content, { size: 'lg' });
  }
  checkDuplicateEmail(){
    if(this.inputForm.controls.email.valid){
      this.accountService.checkDuplicateEmail({value: this.inputForm.controls.email.value, id: this.inputForm.controls.userRefId.value}).pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: GenericResponseTemplateModel<boolean>) => {
        this.isDuplicateEmail = data.responseDataModel;
      });
    }
  }
  onEmailFocusOut(){
    if(this.isDuplicateEmail){
    }
  }
  
  onClickCloseModal() {
    this.modalService.dismissAll();
  }
  
  passClickToFileInput(browserInputId: string) {
    document.getElementById(browserInputId).click();
  }
  onPhotoBrowserInputChange(event, targetImageElementId, aspectRatio) {
    if (event.target.value.length) {
      this.fileUploadEventObject = event;
      this.targetImageElementId = targetImageElementId;
      this.aspectRatio = aspectRatio;
      this.cropModalReference  = this.openCropImageModal(this.cropImageModal);
    }
  }
  onImageCropper(croppedImageInfo: any) {
    this.cropModalReference.close()
    if (croppedImageInfo.targetImageElementId == 'messagePhotoImgElement') {
      this.messageHomePhoto = croppedImageInfo.croppedImage;
      (<HTMLInputElement>document.getElementById('messagePhotoBrowserInput')).value = '';
      //this.inputFormMessage.controls.messageImageBase64.patchValue(this.messageHomePhoto);
      // this.appHttpRequestHandlerService.httpPost({ homeOwnerAddressId: this.homeOwnerAddressId, homeProfileImageBase64: this.defaultHomePhoto }, "HomeOwnerAddress", "UpdateHomeAddressPhoto").pipe(takeUntil(this.ngUnsubscribe))
      //   .subscribe((data: GenericServiceResultTemplate) => { });
    }
    else if (croppedImageInfo.targetImageElementId == 'homePhotoImgElement') {
      this.homePhoto = croppedImageInfo.croppedImage;
      this.inputForm.controls.homeProfileImageBase64.patchValue(this.homePhoto);
    }
  }
  onRemoveImage() {
    this.messageHomePhoto = null;
    this.homePhoto=null;
    this.inputForm.controls.homeProfileImageBase64.patchValue(this.homePhoto);
  }
  onCancelCropImageModal(){
    this.cropModalReference.close('Close click')
  }
  toggleOldPass() {
    this.fieldOldPass = !this.fieldOldPass;
  }
  toggleNewPass() {
    this.fieldNewPass = !this.fieldNewPass;
  }

}
