import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-accept-payment',
  templateUrl: './accept-payment.component.html',
  styleUrls: ['./accept-payment.component.css']
})
export class AcceptPaymentComponent implements OnInit {
  StripeKey: string = environment.StripeKey;
  stripe = Stripe(this.StripeKey);
  elements = this.stripe.elements();
   styleCard =  {
    'style': {
      'base': {
        'fontFamily': 'Arial, sans-serif',
        'fontSize': '8px',
        'color': '#C1C7CD',
      },
      'Invalid': {  'color': 'red', },
    }
  }; 
  

  constructor() {
   
  }

  ngOnInit(): void {
  }
  ngAfterViewInit() {
  var card = this.elements.create('card', this.styleCard);
  //console.log('sdfsfd', card)

  card.mount('#card-element'); 

}
}
